<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>{{ title }} <span>Thumbnail</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <h3 class="sub_header mb-4">Dashboard Thumbnail</h3>
                    <div class="image_setting">
                        <image-library v-model="form.dashboard_thumb" image-type="dashboard-thumb" upload-text="Image" />
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleUpdateThumbnail"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import ImageLibrary from '@/components/image-library/ImageLibrary'

    export default {
        name: 'Assets Edit Thumbnail',

        data () {
            return {
                form: {
                    id: '',
                    title: '',
                    dashboard_thumb: '',
                }
            }
        },

        props: {
            modelValue: Boolean,
            title: String,
            module: {
                type: String,
                default: ''
            },
            selectedAsset: Object,
            refreshAsset: Function,
        },

        emit: ['update:modelValue'],

        components: {
            ImageLibrary
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.resetForm();
                }
            }
        },

        computed: mapState({
            loader: state => state.commonModule.sortDefaultLoader,
        }),

        methods: {
            ...mapActions({
                updateThumbnail: 'commonModule/updateAssetThumbnail'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    id: vm.selectedAsset.id,
                    title: vm.selectedAsset.title ? vm.selectedAsset.title : vm.selectedAsset.name,
                    dashboard_thumb: vm.selectedAsset.dashboard_thumb ? vm.selectedAsset.dashboard_thumb : (vm.selectedAsset.thumb ? vm.selectedAsset.thumb : ''),
                }
            },

            handleUpdateThumbnail () {
                const vm = this;

                if (vm.title == 'Plan') {
                    vm.form.type = 'workout-plan';
                } else {
                    vm.form.type = vm.module ? vm.module.replaceAll(' ', '-').toLowerCase() : vm.title.replaceAll(' ', '-').toLowerCase();
                }

                vm.updateThumbnail(vm.form).then((result) => {
                    if (result) {
                        vm.refreshAsset();
                        vm.closeModal();
                    }
                })
            },
        }
    }
</script>
